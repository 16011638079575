import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "emotion";

const NavBar = ({ links, location }) => (
  <div
    className={css`
      > a {
        color: inherit;
        text-decoration: none;
        opacity: 0.7;
        display: inline-flex;
        align-items: center;
        padding: 0 15px;
        margin-bottom: 15px;
        transition: opacity 0.2s ease;

        &:first-child {
          padding-left: 0;
        }

        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }

        &.active {
          opacity: 1;
          font-weight: 700;
          color: #fff;
        }
      }
    `}
  >
    {links.map(l => (
      <NavLink key={l.to} to={{ ...location, pathname: l.to }} exact={l.exact}>
        {l.label}
      </NavLink>
    ))}
  </div>
);

export default NavBar;
