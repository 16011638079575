import React from "react";
import { css } from "emotion";

const Error = ({ children }) => (
  <div
    className={css`
      color: rgb(197, 40, 61);
      background-color: rgba(197, 40, 61, 0.2);
      border-radius: 4px;
      padding: 0.4em 0.7em;
      margin-top: 10px;
    `}
  >
    {children}
  </div>
);

export default Error;
