import React from "react";
import ReactDOM from "react-dom";
import { css, injectGlobal } from "emotion";
import { PURPLE } from "constants/colors";

injectGlobal`
  @keyframes liftBox {
    from {
      transform: translateY(60px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Modal = ({ title, subtitle, children, onClose }) => (
  <div
    className={css`
      background-color: #fff;
      width: 500px;
      max-width: 100vw;
      max-height: 100vh;
      position: relative;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      color: rgba(60, 60, 60, 0.8);
      animation: liftBox 0.3s;
      border-radius: 6px;
    `}
    onClick={e => e.stopPropagation()}
  >
    {title && (
      <div
        className={css`
          background-color: ${PURPLE};
          padding: 20px;
          color: #fff;
          font-weight: 600;
          font-size: 2rem;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        `}
      >
        {title}
        {subtitle && (
          <div
            className={css`
              font-size: 1.6rem;
              font-weight: 400;
              margin-top: 10px;
              width: 70%;
            `}
          >
            {subtitle}
          </div>
        )}
        <div
          className={css`
            display: flex;
            position: absolute;
            right: 20px;
            top: 20px;
            color: rgba(255, 255, 255, 0.6);
            transition: color 0.2s ease;

            &:hover {
              color: #fff;
              cursor: pointer;
            }
          `}
          onClick={() => onClose()}
        >
          <i className="material-icons">close</i>
        </div>
      </div>
    )}
    <div
      className={css`
        padding: 20px;
      `}
    >
      {children}
    </div>
  </div>
);

const ModalContainer = ({ children, ...rest }) => (
  <div
    className={css`
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(54, 62, 73, 0.8);
      top: 0;
      left: 0;
      z-index: 10000000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
    `}
    onClick={() => rest.onClose()}
  >
    <Modal {...rest}>{children}</Modal>
  </div>
);

const ModalPortal = ({ children, ...rest }) =>
  ReactDOM.createPortal(
    <ModalContainer {...rest}>{children}</ModalContainer>,
    document.getElementById("modal")
  );

export default ModalPortal;
