import React, { Component } from "react";
import { css, injectGlobal } from "emotion";
import { Redirect, withRouter } from "react-router-dom";
import { PURPLE } from "constants/colors";
import { Logo, Input, Button, Loading } from "components";
import { apiPost } from "utils/api";

const duration = 1000;

injectGlobal`
.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity ${duration}ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity ${duration}ms ease-in;
}
`;

const Subtitle = ({ children }) => (
  <div
    className={css`
      color: rgba(255, 255, 255, 0.8);
      font-size: 2.6rem;
      font-weight: 300;
      margin-top: 10px;
      max-width: 700px;
    `}
  >
    {children}
  </div>
);

const Title = ({ children }) => (
  <div
    className={css`
      color: #fff;
      font-size: 4rem;
      font-weight: 700;
      margin-top: 20px;
    `}
  >
    {children}
  </div>
);

const Error = ({ children }) => (
  <div
    className={css`
      background-color: rgba(197, 40, 61, 0.5);
      font-weight: 700;
      color: #fff;
      border-radius: 5px;
      border-radius: 10px;
      display: inline-block;
      padding: 0.6em 1.6em;
      font-size: 2.4rem;
      margin-top: 20px;
    `}
  >
    Sorry! {children}
  </div>
);

const Prompt = ({ login, onChange, loading, errors, embed, name }) => (
  <div
    className={css`
      max-width: 90%;
    `}
  >
    {!embed && <Logo vertical big />}
    <Title>Manage your pets{name ? ` at ${name}` : ""}.</Title>
    <Subtitle>
      Enter your email below, and you'll receive an email from us with a link to
      access your account.
    </Subtitle>
    {errors && errors.map(e => <Error>{e.message}</Error>)}
    <form
      action="#"
      className={css`
        margin: 40px auto;
        width: 500px;
        max-width: 80%;
        font-size: 2.4rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > button {
          margin-top: 15px;
          width: 100%;
        }
      `}
    >
      <Input
        placeholder="Your Email"
        autoFocus
        onChange={value => onChange(value)}
      />
      {loading ? (
        <Loading />
      ) : (
        <Button onClick={() => login()}>Get Access Link</Button>
      )}
    </form>
  </div>
);

const Wait = ({ email, back, embed }) => (
  <div>
    {!embed && <Logo vertical big />}
    <Title>Check your email!</Title>
    <Subtitle>
      To confirm your email address, tap the button in the email we sent to{" "}
      <span
        className={css`
          font-weight: 600;
          color: #fff;
        `}
      >
        {email}
      </span>
      , and you'll log right in.
    </Subtitle>
    <div
      className={css`
        margin-top: 20px;
      `}
    >
      <Button onClick={() => back()}>Try Different Email</Button>
    </div>
  </div>
);

class Login extends Component {
  state = { redirectToReferrer: false, stage: "prompt" };

  login() {
    this.setState({ loading: true });

    const params = new URLSearchParams(this.props.location.search);
    const refUrl = params.get("refUrl");

    apiPost("yourvet/login", { email: this.state.email, refUrl: refUrl }).then(
      r => {
        if (r.resp === "error") {
          this.setState({ errors: r.errors, loading: false });
        } else if (r.resp === "ok") {
          this.setState({ stage: "wait", loading: false });
        }
      }
    );
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    let { redirectToReferrer, stage, email, loading, errors } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    const params = new URLSearchParams(this.props.location.search);
    const embed = params.get("embed");
    const name = params.get("name");

    return (
      <div
        className={css`
          width: 100vw;
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${PURPLE};
          text-align: center;
          padding: 5%;
          box-sizing: border-box;
        `}
      >
        {stage === "prompt" ? (
          <Prompt
            login={() => this.login()}
            onChange={email => this.setState({ email: email })}
            loading={loading}
            errors={errors}
            embed={embed}
            name={name}
          />
        ) : (
          <Wait
            back={() => this.setState({ stage: "prompt" })}
            email={email}
            embed={embed}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Login);
