import React, { Component } from "react";
import { css, injectGlobal } from "emotion";

injectGlobal`
  ::placeholder {
    font-weight: 300;
  }
`;

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = { value: "" };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.autoFocus) this.focus();
  }

  focus() {
    this.inputRef.current.focus();
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({ value: value });
    if (this.props.onChange) this.props.onChange(value);
  }

  render() {
    const { placeholder } = this.props;
    const { value, focus } = this.state;

    const focused = focus || value !== "";

    return (
      <div
        className={css`
          width: 100%;
          position: relative;
          background-color: #fff;
          border-radius: 5px;
          padding: 1.3em 1em 0.6em;
          z-index: 0;
        `}
        onClick={() => this.focus()}
      >
        <input
          type="text"
          ref={this.inputRef}
          placeholder={focused ? placeholder : ""}
          value={value}
          onChange={e => this.handleChange(e)}
          className={css`
            outline: none;
            font: inherit;
            color: inherit;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            width: 100%;
            z-index: 1;
          `}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
        />
        <div
          className={css`
            position: absolute;
            font-weight: 300;
            z-index: -1;
            top: 1em;
            transition: font-size 0.2s ease, top 0.2s ease;

            ${focused && {
              fontSize: "0.7em",
              top: "0.6em"
            }}
          `}
        >
          {placeholder}
        </div>
      </div>
    );
  }
}

export default Input;
