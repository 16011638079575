import React from "react";
import { css } from "emotion";
import { NavBar, Logo } from "components";
import { NAVIGATION_HEIGHT } from "constants/numbers";
import { PURPLE } from "constants/colors";
import { Query, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";

const LINKS = [
  // { label: "Overview", to: "/", exact: true },
  // { label: "My Pets", to: "/pets" },
  // { label: "My Vets", to: "/vets" },
  { label: "Plans", to: "/plans" },
  { label: "Preferences", to: "/preferences" }
];

const GET_CLIENT_INFO = gql`
  query getClientInfo {
    viewer {
      id
      givenName
    }
  }
`;

const Nav = withRouter(
  withApollo(({ client, history, location }) => {
    const signOut = () => {
      client.resetStore();
      localStorage.setItem("authToken", "");
      history.push({ ...location, pathname: `/login` });
    };

    let params = new URLSearchParams(location.search);
    let isEmbed = params.get("embed");

    return (
      <div
        className={css`
          height: ${NAVIGATION_HEIGHT}px;
          background: linear-gradient(to top left, #0864aa, ${PURPLE});
          position: absolute;
          width: 100%;
          z-index: 1;
          color: rgba(255, 255, 255, 1);
          top: 0;
          left: 0;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
          `}
        >
          {isEmbed ? <div /> : <Logo />}
          <div
            onClick={() => signOut()}
            className={css`
              display: flex;
              align-items: center;
              color: rgba(255, 255, 255, 0.8);
              transition: color 0.2s ease;
              &:hover {
                color: #fff;
                cursor: pointer;
              }
            `}
          >
            Sign out&nbsp;&nbsp;
            <i className="material-icons">exit_to_app</i>
          </div>
        </div>

        <div
          className={css`
            padding: 10px 7% 0;
          `}
        >
          <Query query={GET_CLIENT_INFO}>
            {({ data }) => (
              <div
                className={css`
                  font-size: 3rem;
                  margin-bottom: 20px;
                  padding-bottom: 20px;
                  font-weight: 300;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                `}
              >
                Welcome, {data.viewer && data.viewer.givenName}
              </div>
            )}
          </Query>
          <div
            className={css`
              color: rgba(255, 255, 255, 1);
              font-size: 1.8rem;
            `}
          >
            <NavBar links={LINKS} location={location} />
          </div>
        </div>
      </div>
    );
  })
);

export default Nav;
