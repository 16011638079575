import React, { Component } from "react";
import { Card } from "components";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { css } from "emotion";
import Button from "components/Button";
import AddCard from "./AddCard";

const GET_BILLING_INFO = gql`
  query getBillingInfo {
    clients {
      id
      squareCard {
        id
        cardholderName
        cardBrand
        cardLastDigits
        cardExpMonth
        cardExpYear
      }
    }
  }
`;

const CreditCard = ({
  cardholderName,
  cardBrand,
  cardLastDigits,
  cardExpMonth,
  cardExpYear
}) => (
  <div
    className={css`
      border-radius: 6px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      padding: 10px 15px;
    `}
  >
    <div
      className={css`
        font-weight: 700;
        font-size: 1.1em;
      `}
    >
      {cardholderName || "Your Credit Card"}
    </div>
    <div>
      {cardBrand}&nbsp;{cardLastDigits}&nbsp;&nbsp;Exp:&nbsp;{cardExpMonth}/
      {cardExpYear}
    </div>
  </div>
);

class Preferences extends Component {
  state = { addCard: false };

  render() {
    return (
      <div>
        <Query query={GET_BILLING_INFO}>
          {({ data, loading }) => {
            const cards = data.clients
              ? data.clients.map(c => c.squareCard).filter(s => s)
              : [];

            return (
              <Card title="My Billing Information" loading={loading}>
                {cards.length > 0 ? (
                  cards.map(c => <CreditCard key={c.id} {...c} />)
                ) : (
                  <div>
                    No credit cards on file. Automatic billing is{" "}
                    <span
                      className={css`
                        font-weight: 600;
                      `}
                    >
                      disabled
                    </span>
                    .
                  </div>
                )}
                <div
                  className={css`
                    margin-top: 20px;
                  `}
                >
                  <Button onClick={() => this.setState({ addCard: true })}>
                    Update Card
                  </Button>
                </div>
              </Card>
            );
          }}
        </Query>
        {this.state.addCard && (
          <AddCard onClose={() => this.setState({ addCard: false })} />
        )}
      </div>
    );
  }
}

export default Preferences;
