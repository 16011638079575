import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { css, injectGlobal } from "emotion";
import Plans from "pages/Plans";
import Nav from "./Nav";
import Login from "pages/Login";
import ScrollToTop from "./ScrollToTop";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Overview from "pages/Overview";
import Preferences from "pages/Preferences";

import "normalize.css";
import { NAVIGATION_HEIGHT } from "./constants/numbers";

injectGlobal`
  html,
  body {
    width: 100%;
    min-height: 100%;
    background-color: rgb(234, 238, 241);    
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
  }

  * {
    box-sizing: border-box;
  }

  label {
    font-weight: 600;
    margin: 10px 0;
    display: inline-block;
  }
`;

const Stage = ({ location }) => (
  <div
    className={css`
      width: 100%;
    `}
  >
    <Nav />
    <div
      className={css`
        margin: ${NAVIGATION_HEIGHT - 80}px 0 100px 0;
        padding: 0 7%;
        width: 100%;
        position: relative;
        z-index: 10;
      `}
    >
      <Switch>
        <Route path="/overview" component={Overview} />
        <Route path="/plans" component={Plans} />
        <Route path="/preferences" component={Preferences} />
        <Redirect to={{ ...location, pathname: "/plans" }} />
      </Switch>
    </div>
  </div>
);

const App = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route path="/login" component={Login} />
        <AuthenticatedRoute path="/" component={Stage} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default App;
