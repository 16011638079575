import React from "react";
import Select from "react-select";

const OrgSelect = ({ orgs, value, onChange }) => {
  if (!value && orgs && orgs.length > 0) {
    const o = orgs[0];
    onChange({ value: o.id, label: o.name });
  }

  // console.log(value, (orgs || []).map(o => ({ value: o.id, label: o.name })));

  return (
    <Select
      options={(orgs || []).map(o => ({ value: o.id, label: o.name }))}
      onChange={o => onChange(o)}
      placeholder="Select Your Clinic"
      value={value}
    />
  );
};

export default OrgSelect;
