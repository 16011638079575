import React from "react";
import { css } from "emotion";
import { COOL_PURPLE } from "../constants/colors";

const ProfileIcon = ({ profileImageUrl, name }) => (
  <div
    className={css`
      background-color: ${COOL_PURPLE};
      background-image: url(${profileImageUrl});
      background-size: cover;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
      box-sizing: content-box;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      &:before {
        content: attr(data-name);
        position: absolute;
        left: 30px;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-weight: 400;
        font-size: 1.3rem;
        padding: 0.3em 0.5em;
        opacity: 0;
        border-radius: 3px;
        transition: opacity 0.2s ease;
      }

      &:hover:before {
        opacity: 1;
      }
    `}
    data-name={name || "Your Pet"}
  >
    {!profileImageUrl && name && name.slice(0, 1)}
  </div>
);

export default ProfileIcon;
