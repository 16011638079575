import React, { Component } from "react";
import { Modal, OrgSelect, Loading, Error } from "components";
import SquarePaymentForm from "react-square-hosted-fields";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { injectGlobal } from "emotion";

injectGlobal`
  .sq-input input {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    font-size: 16px;
  }
`;

const GET_ORGS = gql`
  query getOrgs {
    clients {
      id
      orgs {
        id
        name
        enableSquare
        squareClientId
      }
    }
  }
`;

const ADD_CLIENT_CARD_ON_FILE = gql`
  mutation addClientCardOnFile($orgId: ID!, $clientId: ID!, $nonce: String!) {
    addSquareCreditCard(orgId: $orgId, clientId: $clientId, nonce: $nonce) {
      id
      squareCard {
        id
        cardholderName
        cardBrand
        cardLastDigits
        cardExpMonth
        cardExpYear
      }
    }
  }
`;

class AddCard extends Component {
  state = { org: null, errors: [] };

  handleNonceError(e) {
    this.setState({ errors: e.map(e => e.message) });
  }

  render() {
    return (
      <Modal
        title="Add a credit card"
        subtitle="Store a credit card on file, and you'll be able to pay for services at the touch of a button."
        onClose={() => this.props.onClose()}
      >
        <Query query={GET_ORGS}>
          {({ data, loading }) => {
            if (loading || !data.clients) return <Loading />;

            const orgs = data.clients
              .reduce((acc, curr) => {
                acc.push(curr.orgs);
                return acc;
              }, [])
              .flat()
              .reduce((acc, curr) => {
                const idx = acc.findIndex(o => o.id === curr.id);
                if (idx === -1) acc.push(curr);
                return acc;
              }, [])
              .filter(o => o.enableSquare);

            if (orgs.length === 0)
              return (
                <div>
                  Sorry, none of your clinics support automated billing yet.
                  Please check back in later.
                </div>
              );

            return (
              <div>
                <label>Select Your Clinic:</label>
                <OrgSelect
                  orgs={orgs}
                  value={this.state.org}
                  onChange={org => this.setState({ org })}
                />

                {this.state.org && (
                  <div>
                    <Mutation
                      mutation={ADD_CLIENT_CARD_ON_FILE}
                      variables={{ orgId: this.state.org.value }}
                    >
                      {mutate => (
                        <SquarePaymentForm
                          appId={
                            orgs.find(o => this.state.org.value === o.id)
                              .squareClientId
                          }
                          onNonceGenerated={e =>
                            mutate({
                              variables: {
                                nonce: e,
                                clientId: data.clients.find(c =>
                                  c.orgs.some(
                                    o => o.id === this.state.org.value
                                  )
                                ).id
                              }
                            })
                              .then(() => this.props.onClose())
                              .catch(e => {
                                this.setState({
                                  errors: [e.message]
                                });
                              })
                          }
                          onNonceError={e => this.handleNonceError(e)}
                          submitText="Save Payment Details"
                          inputStyles={[
                            {
                              fontSize: "16px"
                            }
                          ]}
                        />
                      )}
                    </Mutation>
                  </div>
                )}

                {this.state.errors.map(e => (
                  <Error key={e}>{e}</Error>
                ))}
              </div>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export default AddCard;
