import React from "react";
import ReactDOM from "react-dom";
import ApolloClient from "apollo-boost";
import App from "./App";
import { ApolloProvider } from "react-apollo";
import { API_URL } from "./constants/strings";

import * as serviceWorker from "./serviceWorker";

const client = new ApolloClient({
  uri: API_URL + "/yourvet/graphql",
  request: operation => {
    const token = localStorage.getItem("authToken");
    operation.setContext({
      headers: {
        Authorization: token
      }
    });
  },
  onError: data => {
    const { graphQLErrors, networkError } = data;
    if (graphQLErrors) {
      console.error(graphQLErrors);
    }
    if (networkError) {
      if (networkError.response && networkError.response.status === 403) {
        client.resetStore();
        localStorage.setItem("authToken", "");
        window.location.href = "https://your.vet";
      } else {
        console.error(networkError);
      }
    }
  },
  clientState: {
    defaults: {},
    resolvers: {},
    typeDefs: ``
  }
});

const Site = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

ReactDOM.render(<Site />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
