export function formatCurrency(decimal) {
  if (!decimal) return "$0.00";
  return (
    "$" +
    parseFloat(decimal).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  );
}
