import React, { Component } from "react";
import { ProfileIcon, Button, Modal, Loading } from "components";
import { css, cx } from "emotion";
import moment from "moment";
import { formatCurrency } from "utils";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { GET_SUBSCRIPTIONS } from "./";

const FrontCard = ({
  color,
  name,
  org,
  patients,
  subscribedAt,
  savings,
  earned,
  client,
  onFlip
}) => (
  <div
    className={css`
      background-color: ${color || "rgb(89, 105, 226)"};
      color: #fff;
      padding: 20px;
      z-index: 2;
      /* for firefox 31 */
      transform: rotateY(0deg);
    `}
  >
    <div
      className={css`
        font-weight: 700;
        font-size: 4rem;
      `}
    >
      {name}
    </div>
    <div
      className={css`
        color: rgba(255, 255, 255, 0.7);
      `}
    >
      Member Since{" "}
      {moment(subscribedAt)
        .local()
        .format("MMMM D, YYYY")}
    </div>
    {(savings || earned) && (
      <div
        className={css`
          margin-top: 40px;
          font-size: 3rem;
          font-weight: 300;
        `}
      >
        You've {earned ? "earned" : "saved"}{" "}
        <span
          className={css`
            font-weight: 600;
          `}
        >
          {formatCurrency(earned ? earned : savings)}
        </span>
        .
      </div>
    )}
    <div
      className={css`
        position: absolute;
        bottom: 20px;
        opacity: 0.5;
      `}
    >
      <div>Member #{client.id}</div>
      <div>{org.name}</div>
    </div>
    <div
      className={css`
        position: absolute;
        right: 20px;
        top: 20px;

        > div {
          margin-bottom: 8px;
        }
      `}
    >
      {patients.map(p => (
        <ProfileIcon key={p.id} {...p} />
      ))}
    </div>

    <i
      className={cx(
        "material-icons",
        css`
          position: absolute;
          bottom: 20px;
          right: 20px;
          color: rgba(255, 255, 255, 0.6);
          transition: color 0.2s ease;
          font-size: 2rem;
          margin-right: 4px;

          &:hover {
            cursor: pointer;
            color: #fff;
          }
        `
      )}
      onClick={() => onFlip()}
    >
      settings
    </i>
  </div>
);

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($id: ID!) {
    cancelSubscription(id: $id) {
      id
    }
  }
`;

class BackCard extends Component {
  state = { cancel: false, loading: false };

  render() {
    const { id, description, onFlip } = this.props;

    return (
      <div
        className={css`
          transform: rotateY(180deg);
          background-color: #fff;
          color: rgb(60, 60, 60);
          padding: 20px;
        `}
      >
        <div
          className={css`
            > div {
              margin-bottom: 30px;
            }
          `}
        >
          <div>{description}</div>
          <div>
            <div
              className={css`
                font-weight: 700;
                margin-bottom: 10px;
              `}
            >
              Do you need to cancel?
            </div>
            <Button onClick={() => this.setState({ cancel: true })}>
              Cancel Plan
            </Button>
          </div>
        </div>
        <i
          className={cx(
            "material-icons",
            css`
              position: absolute;
              bottom: 20px;
              right: 20px;
              color: rgba(60, 60, 60, 0.7);
              transition: color 0.2s ease;
              font-size: 2rem;
              margin-right: 4px;

              &:hover {
                cursor: pointer;
                color: rgba(60, 60, 60, 1);
              }
            `
          )}
          onClick={() => onFlip()}
        >
          arrow_forward
        </i>
        {this.state.cancel && (
          <Modal
            title="Cancel Plan"
            subtitle="We're sorry to see you go."
            onClose={() => this.setState({ cancel: false })}
          >
            <div>Are you sure you want to cancel your plan?</div>
            {this.props.minimumMonths &&
              this.props.minimumMonths > 0 &&
              this.props.chargedTimes < this.props.minimumMonths && (
                <div
                  className={css`
                    margin: 10px 0;
                  `}
                >
                  <strong>
                    Because this plan has a minimum of{" "}
                    {this.props.minimumMonths} months of enrollment. You will be
                    charged a{" "}
                    {formatCurrency(
                      (this.props.patients || [1]).length *
                        (parseFloat(this.props.monthlyPrice) *
                          (this.props.minimumMonths - this.props.chargedTimes))
                    )}{" "}
                    cancellation fee.
                  </strong>
                </div>
              )}
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
              `}
            >
              <Button light onClick={() => this.setState({ cancel: false })}>
                Do Not Cancel
              </Button>
              <Mutation
                mutation={CANCEL_SUBSCRIPTION}
                variables={{ id: id }}
                update={(store, { data: { cancelSubscription } }) => {
                  const data = store.readQuery({ query: GET_SUBSCRIPTIONS });
                  for (const client of data.clients) {
                    for (const patient of client.patients) {
                      const idx = patient.patientSubscriptions.findIndex(
                        s => s.id === cancelSubscription.id
                      );
                      if (idx !== -1) {
                        patient.patientSubscriptions.splice(idx, 1);
                        break;
                      }
                    }
                  }

                  store.writeQuery({ query: GET_SUBSCRIPTIONS, data });
                }}
              >
                {mutate =>
                  this.state.loading ? (
                    <Loading />
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({ loading: true });
                        mutate()
                          .then(() =>
                            this.setState({ cancel: false, loading: false })
                          )
                          .catch(() => {
                            alert(
                              "An error occurred cancelling your subscription. Please call us for help."
                            );
                            this.setState({ cancel: false, loading: false });
                          });
                      }}
                    >
                      Cancel Plan!
                    </Button>
                  )
                }
              </Mutation>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

class PlanCard extends Component {
  state = { showBack: false };

  render() {
    const {
      id,
      color,
      name,
      org,
      patients,
      description,
      minimumMonths,
      chargedTimes,
      monthlyPrice,
      subscribedAt,
      client,
      savings,
      earned
    } = this.props;
    const { showBack } = this.state;

    return (
      <div
        className={css`
          width: 500px;
          height: 250px;
          border-radius: 10px;
          position: relative;
          perspective: 1000px;
          max-width: 100%;
        `}
      >
        <div
          className={css`
            position: relative;
            transition: 0.8s;
            transform-style: preserve-3d;
            height: 100%;
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 10px 30px 0 rgba(14, 30, 37, 0.2);

            ${showBack && {
              transform: "rotateY(-180deg)"
            }}

            > div {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              position: absolute;
              top: 0;
              left: 0;
              backface-visibility: hidden;
            }
          `}
        >
          <FrontCard
            color={color}
            name={name}
            org={org}
            client={client}
            patients={patients}
            savings={savings}
            earned={earned}
            subscribedAt={subscribedAt}
            onFlip={() => this.setState({ showBack: true })}
          />
          <BackCard
            id={id}
            description={description}
            minimumMonths={minimumMonths}
            chargedTimes={chargedTimes}
            patients={patients}
            monthlyPrice={monthlyPrice}
            onFlip={() => this.setState({ showBack: false })}
          />
        </div>
      </div>
    );
  }
}

export default PlanCard;
