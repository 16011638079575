import React from "react";
import { css } from "emotion";
import Loading from "./Loading";

const Card = ({ children, full, title, width, loading }) => (
  <div
    className={css`
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 10px 30px 0 rgba(14, 30, 37, 0.2);
      margin-bottom: 30px;
      width: ${width ? width + "px" : "750px"};
      max-width: 100%;
    `}
  >
    <div
      className={css`
        padding: ${full ? "0" : "15px 20px"};
      `}
    >
      {title && (
        <div
          className={css`
            font-weight: 700;
            margin: 5px 0 15px;
            color: rgba(60, 60, 60, 0.95);
            font-size: 2rem;
          `}
        >
          {title}
        </div>
      )}
      {loading && <Loading />}
      {children}
    </div>
  </div>
);

export default Card;
