import React from "react";
import PlanCard from "./PlanCard";
import { css } from "emotion";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Card } from "components";

export const GET_SUBSCRIPTIONS = gql`
  query getSubscriptions {
    clients {
      id
      patients {
        id
        name
        profileImageUrl
        patientSubscriptions {
          id
          subscribedAt
          chargedTimes
          subscription {
            id
            name
            color
            description
            minimumMonths
            monthlyPrice
            org {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const NoSubs = () => <Card>No current plans.</Card>;

const Plans = () => (
  <Query query={GET_SUBSCRIPTIONS}>
    {({ data }) => {
      const subscriptions = [];

      if (data.clients)
        for (const client of data.clients)
          for (const patient of client.patients)
            if (
              patient.patientSubscriptions &&
              patient.patientSubscriptions.length > 0
            )
              for (const s of patient.patientSubscriptions)
                subscriptions.push({
                  id: s.id,
                  name: s.subscription.name,
                  subscribedAt: s.subscribedAt,
                  org: s.subscription.org,
                  client: client,
                  color: s.subscription.color,
                  description: s.subscription.description,
                  chargedTimes: s.chargedTimes,
                  monthlyPrice: s.subscription.monthlyPrice,
                  minimumMonths: s.subscription.minimumMonths,
                  patients: [patient]
                });

      return (
        <div
          className={css`
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            > div {
              min-width: 300px;
              margin-bottom: 30px;
              margin-right: 30px;
            }
          `}
        >
          {subscriptions.length === 0 ? (
            <NoSubs />
          ) : (
            subscriptions.map(s => <PlanCard key={s.id} {...s} />)
          )}
        </div>
      );
    }}
  </Query>
);

export default Plans;
