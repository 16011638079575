import React from "react";
import { css } from "emotion";
import VetspireLogo from "images/Vetspire-logo.png";

const Logo = ({ big, vertical }) => (
  <div
    className={css`
      color: rgba(255, 255, 255, 1);
      font-size: ${big ? "3rem" : "1.8rem"};
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: ${vertical ? "column" : "row"};
    `}
  >
    <img
      src={VetspireLogo}
      className={css`
        width: ${big ? "64px" : "32px"};
        height: ${big ? "64px" : "32px"};
        margin-top: -2px;
      `}
      alt="Logo"
    />
    <div>Your.Vet</div>
  </div>
);

export default Logo;
