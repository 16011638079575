import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthenticatedRoute = ({ component: Component, history, ...rest }) => {
  let params = new URLSearchParams(rest.location.search);
  let authToken = params.get("u");

  if (authToken) {
    localStorage.setItem("authToken", authToken);
  } else {
    authToken = localStorage.getItem("authToken");
  }

  return (
    <Route
      {...rest}
      render={props =>
        authToken && authToken !== "" && authToken !== "None" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              ...rest.location,
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
